import { useEffect, useState } from "react";
import { Drawer, notification, Table, Tag } from "antd";
import Page from "../../../../elements/Page";
import HeaderItem from "../../../../elements/HeaderItem";
import TableHead from "./TableHead";
import AddButtonComponent from "../../../../elements/AddButtonComponent";
import REQUESTS from "../../../../../server/requests";
import RoomPageDrawer from "./drawer/RoomPageDrawer";
import TableButtons from "../../../../elements/TableButtons";
import ICONS from "../../../../../config/icons";
import confirm from "antd/lib/modal/confirm";
import { useSelector } from "react-redux";
import EN from "../../../../../config/en";
import NewGalleryDrawer from "./drawer/NewGalleryDrawer";
import GalleryComponent from "../../../../elements/GalleryComponent";
import GalleriesDrawer from "./drawer/GalleryDrawer";

const RoomPage = () => {
  const translation = useSelector((state) => state.globalState.translate);

  const [dataSource, setDataSource] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);

  const [showDrawer, setShowDrawer] = useState(false);
  const [editable, setEditable] = useState(null);
  const [propertiesOptions, setPropertiesOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showGallery, setShowGallery] = useState(false);
  const [roomInfo, setRoomInfo] = useState(null);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      fixed: "left",
      align: "center",
      width: 50,
    },
    {
      title: translation["Name"] || EN["Name"],
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: translation["Description"] || EN["Description"],
      dataIndex: "description",
      key: "description",
      align: "center",
      ellipsis: true,
    },
    {
      title: translation["Price"] || EN["Price"],
      dataIndex: "price",
      key: "price",
      align: "center",
    },
    {
      title: translation["Adults"] || EN["Adults"],
      dataIndex: "adults",
      key: "adults",
      align: "center",
    },
    {
      title: translation["Children"] || EN["Children"],
      dataIndex: "chld",
      key: "chld",
      align: "center",
    },
    {
      title: translation["Twin Beds"] || EN["Twin Beds"],
      dataIndex: "twin_beds",
      key: "twin_beds",
      align: "center",
    },
    {
      title: translation["Sofa Bed"] || EN["Sofa Bed"],
      dataIndex: "sofa_bed",
      key: "sofa_bed",
      align: "center",
    },
    {
      title: translation["Queen Bed"] || EN["Queen Bed"],
      dataIndex: "queen_bed",
      key: "queen_bed",
      align: "center",
    },
    {
      title: translation["Size"] || EN["Size"],
      dataIndex: "size",
      key: "size",
      align: "center",
    },
    {
      title: translation["Properties"] || EN["Property"],
      dataIndex: "properties",
      key: "properties",
      align: "center",

      render: (properties) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 5,
            }}
          >
            {properties?.map((item, idx) => {
              return <Tag key={idx}>{item.title ? item?.title : "N/A"}</Tag>;
            })}
          </div>
        );
      },
    },
    {
      title: translation["Actions"] || EN["Actions"],
      dataIndex: "actions",
      key: "actions",
      align: "center",
      fixed: "right",
      width: 100,
      render: (text, record) => {
        return (
          <TableButtons
            handleMenuClick={(e) => handleMenuClick(e.key, record)}
            buttons={[
              { key: "edit", text: translation["Edit"] || EN["Edit"], icon: ICONS.EDIT },
              {
                key: "gallery",
                text: translation["Gallery"] || EN["Gallery"],
                icon: ICONS.GALLERIES,
              },
              {
                key: "delete",
                text: translation["Delete"] || EN["Delete"],
                icon: ICONS.DELETE,
              },
            ]}
          />
        );
      },
    },
  ];

  const handleMenuClick = (key, record) => {
    if (key === "edit") {
      setEditable(record);
      setShowDrawer(true);
    } else if (key === "delete") {
      confirm({
        title: translation["Do you want to delete"] || EN["Do you want to delete"],
        onOk() {
          handleDelete(record.id);
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    } else if (key === "gallery") {
      console.log("Gallery");
      setShowGallery(true);
      setRoomInfo(record);
    }
  };

  const handleClickDrawer = () => {
    setShowDrawer(true);
  };

  const handleTableChange = (data) => {
    setCurrentPage(data.current);
    setLimit(data.pageSize);
  };

  const handleSave = (values) => {
    setLoading(true);
    try {
      if (editable) {
        const successCallBack = (data) => {
          setLoading(false);
          setShowDrawer(false);
          setEditable(null);
          getDataSource();
          notification.success({
            message:
              translation["Room updated successfully"] || EN["Room updated successfully"],
          });
        };

        const errorCallBack = (error) => {
          setLoading(false);

          notification.error({
            message: translation["An error occurred"] || EN["An error occurred"],
          });
          console.log(error);
        };

        REQUESTS.BOOKING.ROOM.EDIT(values, editable.id, successCallBack, errorCallBack);
      } else {
        const successCallBack = (data) => {
          setLoading(false);

          getDataSource();
          setShowDrawer(false);

          notification.success({
            message:
              translation["Room added successfully"] || EN["Room added successfully"],
          });
        };

        const errorCallBack = (error) => {
          setLoading(false);

          notification.error({
            message: translation["An error occurred"] || EN["An error occurred"],
          });
          console.log(error);
        };

        REQUESTS.BOOKING.ROOM.ADD(values, successCallBack, errorCallBack);
      }
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };

  const handleDelete = (id) => {
    setLoading(true);
    try {
      const successCallBack = (data) => {
        setLoading(false);
        getDataSource();
        notification.success({
          message:
            translation["Room deleted successfully"] || EN["Room deleted successfully"],
        });
      };

      const errorCallBack = (error) => {
        setLoading(false);

        notification.error({
          message: translation["An error occurred"] || EN["An error occurred"],
        });
        console.log(error);
      };

      REQUESTS.BOOKING.ROOM.DELETE(id, successCallBack, errorCallBack);
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };

  const getDataSource = () => {
    setLoading(true);

    try {
      const query = {
        limit,
        page: currentPage,
        total,
      };
      const successCallBack = (response) => {
        setLoading(false);
        const data = response?.rows.map((item) => {
          return {
            name: item.name,
            description: item.description,
            price: item.price,
            id: item.id,
            adults: item.adults,
            queen_bed: item.queen_bed,
            properties: item.properties,
            size: item.size,
            sofa_bed: item.sofa_bed,
            twin_beds: item.twin_beds,
            properties: item.properties,
            chld: item.children,
          };
        });
        setDataSource(data);
        setTotal(response?.count);
        setCurrentPage(response?.page);
        setLimit(response?.limit);
      };

      const errorCallBack = (error) => {
        setLoading(false);
        notification.error({
          message: translation["An error occurred"] || EN["An error occurred"],
        });
      };

      REQUESTS.BOOKING.ROOM.GET(query, successCallBack, errorCallBack);
    } catch (error) {
      console.error("Unexpected error:", error);
    }
  };

  useEffect(() => {
    REQUESTS.BOOKING.PROPERTY.GET(
      {},
      (data) => {
        const properties = data?.rows?.map((item) => {
          return {
            value: item.id,
            label: item.title,
          };
        });
        setPropertiesOptions(properties);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  useEffect(() => {
    getDataSource();
  }, [limit, currentPage]);

  return (
    <>
      <Page>
        <div className="page-body">
          <h1>{translation["Rooms"] || EN["Rooms"]}</h1>
          <HeaderItem
            title={translation["Rooms"] || EN["Rooms"]}
            total={dataSource?.length}
          />
          <div
            style={{
              marginBottom: 30,
            }}
          >
            <TableHead>
              <AddButtonComponent
                text={translation["Add Room"] || EN["Add Room"]}
                onClick={handleClickDrawer}
              />
            </TableHead>
          </div>
          <Table
            dataSource={dataSource || []}
            columns={columns}
            onChange={handleTableChange}
            scroll={{ x: 1800 }}
            loading={loading}
            pagination={{
              position: ["bottomCenter"],
              current: currentPage,
              pageSize: limit,
              total,
              showSizeChanger: true,
            }}
          />
        </div>
        <RoomPageDrawer
          open={showDrawer}
          close={() => {
            setShowDrawer(false);
            setEditable(null);
          }}
          handleSave={handleSave}
          editable={editable}
          propertiesOptions={propertiesOptions}
          loading={loading}
        />
        <GalleriesDrawer
          open={showGallery}
          roomInfo={roomInfo}
          onClose={() => {
            setShowGallery(false);
          }}
        />
      </Page>
    </>
  );
};

export default RoomPage;
