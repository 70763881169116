import { Routes, Route } from "react-router-dom";

import NAVIGATORS from "./config/navigators";

import Root from "./components/root/Root";

import NewsPage from "./components/pages/promotions/news/NewsPage";
import PromotionsCategoryPage from "./components/pages/promotions/categories/Page";

import MediaLibraryPage from "./components/pages/media-library/MediaLibraryPage";
import MonitoringPage from "./components/pages/monitoring/MonitoringPage";

import InfoCenterCategoriesPage from "./components/pages/info-center/categories/Page";
import InfoCenterPage from "./components/pages/info-center/info/Page";

import RoomsPage from "./components/pages/organizations/locations/RoomsPage";
import SingleRoomPage from "./components/pages/organizations/locations/single-room/SingleRoomPage";

import OrganizationsCategoriesPage from "./components/pages/organizations/categories/Page";
import HostGroupsPage from "./components/pages/organizations/host-groups/Page";

import PackegesPage from "./components/pages/packages/PackegesPage";

import OrdersPage from "./components/pages/orders/OrdersPage";

import WelcomPage from "./components/pages/welcome/WelcomePage";

import MessagesPage from "./components/pages/messages/MessagesPage";

import SettingsPage from "./components/pages/settings/SettingsPage";

import DashboardPage from "./components/pages/dashboard/DashboardPage";

import LoginPage from "./components/pages/login-registration/LoginPage";
import ResetPasswordPage from "./components/pages/login-registration/ResetPasswordPage";
import CheckEmailPage from "./components/pages/login-registration/CheckEmailPage";

import TranslationPage from "./components/pages/translation/TranslationPage";

import CategoriesPage from "./components/pages/e-commerce/categories/CategoriesPage";
import ProductsPage from "./components/pages/e-commerce/products/ProductsPage";
import ServicesPage from "./components/pages/e-commerce/services/ServicesPage";

import TVApplicationPage from "./components/pages/tv-application/TVApplicationPage";

import TvCannalsPage from "./components/pages/media/live-tv/tv-channals/TvChannalsPage.jsx";
import LiveTVCategoriesPage from "./components/pages/media/live-tv/categories/LiveTVCategoriesPage";
import EpgLinksPage from "./components/pages/media/live-tv/epg-links/EpgLinksPage";
import VodGenres from "./components/pages/media/vod/genres/VodGenres";
import MovesPage from "./components/pages/media/vod/moves/MovesPage";

import ApplicationPage from "./components/pages/application/Page";
import ScreenMirroringPage from "./components/pages/screen-mirroring/Page";
import HelpInfoPage from "./components/pages/help-info/Page";

import NoFound from "./components/root/404";
import "./App.scss";

import "antd/dist/antd.css";
import OrganizationCompaniesPage from "./components/pages/organizations/companies/OrganizationCompaniesPage";
import { useSelector } from "react-redux";
import MusicPage from "./components/pages/media/music/MusicPage.jsx";
import RadioPAge from "./components/pages/media/radio/RadioPage.jsx";
import RadioGenres from "./components/pages/media/radio/RadioGenres.jsx";
import MusicGenres from "./components/pages/media/music/MusicGenres.jsx";
import AboutPage from "./components/pages/booking/about/AboutPage.jsx";
import CustomerStoryPage from "./components/pages/booking/customer-story/CustomerStoryPage.jsx";
import CustomerPage from "./components/pages/booking/customers/CustomersPage.jsx";
import SocialLinksPage from "./components/pages/booking/social-links/SocialLinksPage.jsx";
import ArticlePage from "./components/pages/booking/article/ArticlePage.jsx";
import ServicePage from "./components/pages/booking/service/ServicePage.jsx";
import BlogPage from "./components/pages/booking/blog/BlogPage.jsx";
import SocialAuthPage from "./components/pages/booking/social-auth-configs/SocialAuthPage.jsx";
import SLiderPage from "./components/pages/booking/slider-page/SliderPage.jsx";
import BillingHistoryPage from "./components/pages/booking/billing-history/BillingHistoryPage.jsx";
import PropertyPage from "./components/pages/booking/room/property/PropertyPage.jsx";
import FacilitiesPage from "./components/pages/booking/room/facilities/FacilitiesPage.jsx";
import RoomPage from "./components/pages/booking/room/room-page/RoomPage.jsx";
import BookPage from "./components/pages/booking/room/book/BookPage.jsx";
import { useEffect } from "react";

function App() {
  const { profile } = useSelector((state) => state.globalState);

  const entryHandler = (entries) => {
    for (let entry of entries) {
      console.log(entries[entry]);
    }
  };

  const resizeObserver = new ResizeObserver(entryHandler);

  const element = document.getElementById("root");

  resizeObserver?.observe(element);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<WelcomPage />} />
        <Route path={NAVIGATORS.LOGIN} element={<LoginPage />} />
        <Route path={NAVIGATORS.RESET_PASSWORD} element={<ResetPasswordPage />} />
        <Route path={NAVIGATORS.CHECK_EMAIL} element={<CheckEmailPage />} />
        <Route path="*" element={<NoFound />} />

        <Route path={NAVIGATORS.DASHBOARD} element={<Root />}>
          <Route path={NAVIGATORS.DASHBOARD_CONTENT} element={<DashboardPage />} index />
          <Route path={NAVIGATORS.MESSAGES} element={<MessagesPage />} />
          <Route path={NAVIGATORS.LOCATIONS} element={<RoomsPage />} />
          {profile?.is_super_admin && (
            <Route
              path={NAVIGATORS.ORGANIZATION_COMPANIES}
              element={<OrganizationCompaniesPage />}
            />
          )}

          <Route
            path={NAVIGATORS.ORGANIZATION_CATEGORIES}
            element={<OrganizationsCategoriesPage />}
          />
          <Route path={NAVIGATORS.HOST_GROUPS} element={<HostGroupsPage />} />
          <Route path={NAVIGATORS.PACKAGES} element={<PackegesPage />} />
          <Route path={NAVIGATORS.ORDERS} element={<OrdersPage />} />
          <Route path={NAVIGATORS.PROMOTIONS_NEWS} element={<NewsPage />} />
          <Route
            path={NAVIGATORS.PROMOTIONS_CATEGORIES}
            element={<PromotionsCategoryPage />}
          />
          <Route path={NAVIGATORS.MEDIA_LIBRARY} element={<MediaLibraryPage />} />

          <Route path={NAVIGATORS.MONITORING} element={<MonitoringPage />} />

          <Route
            path={NAVIGATORS.INFO_CENTER_CATEGORIES}
            element={<InfoCenterCategoriesPage />}
          />
          <Route path={NAVIGATORS.INFO_CENTER} element={<InfoCenterPage />} />

          <Route path={NAVIGATORS.ECOMMERCE_CATEGORIES} element={<CategoriesPage />} />
          <Route path={NAVIGATORS.ECOMMERCE_PRODUCTS} element={<ProductsPage />} />
          <Route path={NAVIGATORS.ECOMMERCE_SERVICES} element={<ServicesPage />} />

          {/* * * * * * * BOOKING * * * * * * * */}

          <Route path={NAVIGATORS.BOOKING_ABOUT} element={<AboutPage />} />
          <Route path={NAVIGATORS.BOOKING_CUSTOMERS} element={<CustomerPage />} />
          <Route path={NAVIGATORS.BOOKING_SOCIAL_MEDIA} element={<SocialLinksPage />} />
          <Route path={NAVIGATORS.BOOKING_ARTICLES} element={<ArticlePage />} />
          <Route path={NAVIGATORS.BOOKING_SERVICE} element={<ServicePage />} />
          <Route path={NAVIGATORS.BOOKING_BLOG} element={<BlogPage />} />
          <Route path={NAVIGATORS.BOOKING_SOCIAL_AUTH} element={<SocialAuthPage />} />
          <Route path={NAVIGATORS.BOOKING_SLIDER} element={<SLiderPage />} />
          <Route
            path={NAVIGATORS.BOOKING_BILLING_HISTORY}
            element={<BillingHistoryPage />}
          />

          <Route
            path={NAVIGATORS.BOOKING_CUSTOMER_STORY}
            element={<CustomerStoryPage />}
          />
          <Route path={NAVIGATORS.BOOKING_ROOM_ROOMS} element={<RoomPage />} />

          <Route path={NAVIGATORS.BOOKING_ROOM_PROPERTIES} element={<PropertyPage />} />

          <Route path={NAVIGATORS.BOOKING_ROOM_FACILITIES} element={<FacilitiesPage />} />
          <Route path={NAVIGATORS.BOOKING_BOOK} element={<BookPage />} />

          {/* * * * * * * MEDIA * * * * * * * */}
          <Route
            path={NAVIGATORS.LIVE_TV_CATEGORIES}
            element={<LiveTVCategoriesPage />}
          />

          <Route path={NAVIGATORS.LIVE_TV_EPG_LINKS} element={<EpgLinksPage />} />

          <Route path={NAVIGATORS.LIVE_TV_TVCHANNELS} element={<TvCannalsPage />} />

          <Route path={NAVIGATORS.MEDIA_VOD_GENRES} element={<VodGenres />} />
          <Route path={NAVIGATORS.MEDIA_VOD_MOVES} element={<MovesPage />} />
          <Route path={NAVIGATORS.MUSIC} element={<MusicPage />} />
          <Route path={NAVIGATORS.MUSIC_GENRES} element={<MusicGenres />} />
          <Route path={NAVIGATORS.RADIO} element={<RadioPAge />} />
          <Route path={NAVIGATORS.RADIO_GENRES} element={<RadioGenres />} />

          <Route path={NAVIGATORS.SETTINGS} element={<SettingsPage />} />
          <Route path={NAVIGATORS.TV_APPLICATION} element={<TVApplicationPage />} />
          <Route path={NAVIGATORS.TRANSLATION} element={<TranslationPage />} />

          <Route path={NAVIGATORS.LOCATION} element={<SingleRoomPage />} />
          <Route path={NAVIGATORS.APPLICATION} element={<ApplicationPage />} />
          <Route path={NAVIGATORS.SCREEN_MIRRORING} element={<ScreenMirroringPage />} />
          <Route path={NAVIGATORS.HELP_INFO} element={<HelpInfoPage />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
