// import React from "react";
// import { Layout } from "antd";
// import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
// import LanguageBlock from "./LanguageBlock";
// import ProfileBlock from "./ProfileBlock";
// import UploadingList from "./UploadingList";
// import Logo from "./Logo";

// export default function Header({ collapsed, handleCollapsed }) {
//   return (
//     <Layout.Header
//       className="root-header"
//       style={{
//         padding: 0,
//         left: collapsed ? 80 : 250,
//         transition: "0.3s",
//         width: `calc(100% - ${collapsed ? 80 : 250}px)`,
//       }}
//     >
//       <div className="icon-uploader-section">
//         {collapsed ? (
//           <MenuUnfoldOutlined className="trigger" onClick={handleCollapsed} />
//         ) : (
//           <MenuFoldOutlined className="trigger" onClick={handleCollapsed} />
//         )}

//         <UploadingList />
//         <div className="root-header-logo">{/* <Logo /> */}</div>
//       </div>
//       <div className="icon-uploader-section">
//         <LanguageBlock />
//         <ProfileBlock />
//       </div>
//     </Layout.Header>
//   );
// }
import React, { useEffect, useState } from "react";

import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../store";

import REQUESTS from "../../server/requests";

import { Menu, Dropdown, Space } from "antd";

export default function LanguageBlock() {
  const dispatch = useDispatch();

  const { languages, activeLanguages, getTranslation } = useSelector(
    (state) => state.globalState
  );

  const { setLanguages, setTranslate, setActiveLanguages } = bindActionCreators(
    actions,
    dispatch
  );

  const [defaultLanguage, setDefaultLanguage] = useState({});

  const selectDefault = (item) => {
    if (item?.iso_code === defaultLanguage?.iso_code) return;

    const body = {
      id: item.id,
      is_default: true,
    };

    function callback() {
      getLanguages();

      // window.location.reload();
    }
    function errorCallback(err) {
      console.log(err, "--");
    }

    REQUESTS.LANGUAGES.CHANGE(body, callback, errorCallback);
  };

  const getLanguages = () => {
    REQUESTS.LANGUAGES.GET({}, (data) => {
      setLanguages(data);
    });
  };

  const getTranslations = () => {
    if (defaultLanguage.id) {
      const query = {
        languages_id: defaultLanguage.id,
      };

      REQUESTS.TRANSLATIONS.GET(query, (data) => {
        setTranslate(data.translations || {});
      });
    }
  };

  useEffect(() => {
    getTranslations();
  }, [defaultLanguage, getTranslation]);

  useEffect(() => {
    const list = languages.filter((item) => item.is_active);
    const isDefault = languages.find((item) => item.is_default);

    setActiveLanguages(list);
    setDefaultLanguage(isDefault || {});
  }, [languages]);

  useEffect(() => {
    getLanguages();
  }, []);

  const menu = (
    <div className="wrap-leng-menu">
      <Menu>
        {activeLanguages?.map((item) => {
          return (
            <Menu.Item key={item.id} title={item.name}>
              <div className="df-list-lng" onClick={() => selectDefault(item)}>
                <img src={item.icon} width={20} height={15} />
                <span>{item.name}</span>
              </div>
            </Menu.Item>
          );
        })}
      </Menu>
    </div>
  );

  return (
    <Dropdown menu={menu} trigger={["click"]}>
      <Space style={{ color: "#fff", cursor: "pointer", display: "none" }}>
        <img src={defaultLanguage.icon} width={20} height={15} />
        <span style={{ textTransform: "uppercase" }}>{defaultLanguage.code}</span>
      </Space>
    </Dropdown>
  );
}
